import React from 'react'
import underconstruction from "./../../Assets/image/underconstruction.png"

export default function ComingSoon() {
  return (
    <div>
      <div className='m-100 w-fit mx-auto'>
        <h1 className='font-800 text-32 text-center'>COMING SOON</h1>
        <img className='mx-auto' src={underconstruction}></img>
      </div>
    </div>
  )
}
