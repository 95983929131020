import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ComingSoon from "./Pages/404/comingsoon";
import { ProjectDetail } from "./Pages/Projects/Project";

function App() {

  return (
    <Router> 
      <Routes>
        <Route path="/" element={<ComingSoon/>} />
        {/* <Route path="/" element={<Home/>} /> */}
        {/* <Route path="/project/:slug" element={<ProjectDetail/>} /> */}
      </Routes>
    </Router> 
  );
}

export default App;
